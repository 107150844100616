import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Box, Menu } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { MiscTypes, ParamsEnums } from '../../enums'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import './AdaptiveComponentForModalAndPage.scss'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'

function AdaptiveComponentForModalAndPage({
  isModal,
  subheading,
  footer,
  body,
  heading,
  selectedName,
  darkbg = false,
  closeOnChangeOf = ''
}) {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const orgnameref = React.useRef(null)

  const { mode } = useCustomSelector((state) => ({
    mode: state.appInfo.mode,
    
  }))
  useEffect(() => {
    if (mode === MiscTypes.OAUTH_MODE) {
      setAnchorEl(orgnameref.current)
    }
  }, [])


  useEffect(() => {
    handleClose()
  }, [closeOnChangeOf])
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(updateAppInfoState({ isProjectSliderOpen: true }))
    setAnchorEl(event.currentTarget)
     window.closeChatbot()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  // console.log(width, 1917171)
  const pageContent = (
    <Box
      className={` bg-workspace overflow-y-auto ${
        !isModal ? 'workspace__page flex-col-start-center gap-2' : 'workspace__modal absolute '
      } flex-center-start `}
    >
      {!isModal && heading && (
        <Typography variant='h5' className='p-1 pt-5'>
          {heading}
        </Typography>
      )}

      <Box
        className={`w-100 column flex-grow overflow-y-auto ${
          !isModal ? 'workspace__page-cont gap-3' : 'workspace__modal-cont'
        } workspace__cont`}
      >
        {subheading && (
          <Typography variant='subtitle1' className='color-black' align='center'>
            {subheading}
          </Typography>
        )}

        {body && <Box className='workspace__orglist w-100 column gap-1'>{body}</Box>}
        {footer && <Box className='footer'>{footer}</Box>}
      </Box>
    </Box>
  )
  if (isModal)
    return (
      <Box className={`select_workspace flex-start-center p-2 gap-1 text-overflow-eclipse ${anchorEl ? 'selected ' : ''} `}>
        <Box
          className='flex-start-center w-100  cur-pointer text-overflow-eclipse gap-1'
          ref={orgnameref}
          onClick={handleClick}
          aria-controls='account-menu'
          aria-haspopup='true'
          aria-expanded='true'
        >
          {selectedName}
          {anchorEl ? (
            <ExpandLessIcon color={darkbg ? 'white' : 'black'} fontSize='small' />
          ) : (
            <ExpandMoreIcon color={darkbg ? 'white' : 'black'} fontSize='small' />
          )}
        </Box>

        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className='overflow-scroll-y'
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 3,
              '& .MuiAvatar-root': {
                width: 24,
                height: 24,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'none',
                bgcolor: 'none'
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          {pageContent}
        </Menu>
      </Box>
    )
  return pageContent
}
export default React.memo(addUrlDataHoc(React.memo(AdaptiveComponentForModalAndPage), [ParamsEnums.orgId]))
