import { createAsyncThunk } from '@reduxjs/toolkit'
/* eslint-disable-next-line import/no-cycle */
import { createOrg, getEmbedAuthToken, getUserOrg, renameOrg } from '../../api/index'
import { openModalRedux, updateAppInfoState } from '../appInfo/appInfoSlice.ts'
import { switchOrgId } from '../../utils/utilities'
import { MiscTypes } from '../../enums'

export const saveOrgs = createAsyncThunk('orgs/saveOrgs', async (payload, { dispatch }) => {
  const { data } = await createOrg(payload)
  await switchOrgId(data?.data?.id, data?.data?.name)
  dispatch(updateAppInfoState({ currentOrgId: data?.data?.id }))
  return data.data
})

export const fetchOrgs = createAsyncThunk('orgs/fetchOrgs', async (payload, { dispatch, getState }) => {
  const isSSO = getState()?.appInfo?.mode === MiscTypes.SSO_MODE
  const orgList = await fetchAllCompany(1, isSSO)
  if (orgList.length === 0) dispatch(openModalRedux({ modalType: 'createOrg' }))
  return orgList || []
})
export const removeOrgs = createAsyncThunk('orgs/removeOrgs', async () => {
  return 5
})
export const setOrgId = createAsyncThunk('orgs/setOrgId', async (payload) => {
  return payload
})

export const renameOrgThunk = createAsyncThunk('orgs/renameOrgThunk', async (payload) => {
  const { body, orgId } = payload
  const response = await renameOrg(body, orgId)
  return response?.data?.data
})

export const getEmbedTokenThunk = createAsyncThunk('orgs/setEmbedAccessToken', async (payload) => {
  // @author-ArthrajRathore
  const data = await getEmbedAuthToken(payload)
  return { payload: data.data.data, orgId: payload }
})

async function fetchAllCompany(page = 1, is_readable = false) {
  const filter = `pageNo=${page}${is_readable ? '&is_readable=1' : ''}` // add is_readable=1 if passed
  const response = await getUserOrg(filter)
  const users = response?.data
  const totalPageCount = response?.totalPageCount

  if (page < totalPageCount) {
    const nextPageUsers = await fetchAllCompany(page + 1, is_readable)
    return users.concat(nextPageUsers)
  }
  return users
}
