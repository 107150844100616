import cloneDeep from 'lodash.clonedeep'
import { BlockType, InputFieldsType, NewJsonType, StepType } from '../../../types/Plugin.ts'
import { generateNewId, getStringFromUsedVariable } from '../../../utils/utilities'
import { evalVariableAndCodeFromContext } from '../../../utils/codeUtility.ts'
import { InvocationReduxDataType } from '../../../types/Invocation.ts'

export function getContextFromInvocation(invocation: InvocationReduxDataType) {
  return {
    req: invocation?.invocationData?.requestSnapshot || {},
    res: invocation?.invocationData?.responseSnapshot || {},
    vals: invocation?.invocationData?.vals || {}
  }
}

/**
 * Evaluates the visibility condition based on the provided fields and context.
 *
 * @param {any} visibilityCondition - The condition to evaluate, expected to be a string.
 * @param {any} fieldsValue - The values of the fields used in the condition.
 * @param {any} context - The context in which the condition is evaluated.
 * @returns {boolean} - Returns true if the condition is met, otherwise false.
 */
export function calculateVisibilityCondition(visibilityCondition: any, fieldsValue: any, context: any) {
  if (typeof visibilityCondition === 'string' && visibilityCondition) {
    // Check if visibilityCondition is a non-empty string
    const fieldsExecutionCode = getStringFromUsedVariable(fieldsValue)
    return calculateVisibilityConditionViaCode(fieldsExecutionCode, visibilityCondition, context)
  }
  return true
}

/**
 * Evaluates a visibility condition using a given execution code and context.
 *
 * @param {string} fieldsExecutionCode - The code representing the fields' values to be used in the evaluation.
 * @param {string} visibilityCondition - The condition to evaluate, expected to be a string.
 * @param {any} context - The context in which the condition is evaluated.
 * @returns {boolean} - Returns true if the condition is met, otherwise false.
 */
function calculateVisibilityConditionViaCode(fieldsExecutionCode: string, visibilityCondition: string, context: any): boolean {
  const result: any = evalVariableAndCodeFromContext(
    `
      context  = {...context, inputData : ${fieldsExecutionCode} }
      return !!(${visibilityCondition})
  `,
    context
  )
  if (result.success === false) return false
  return typeof result.message === 'boolean' ? result.message : false
}

/**
 * Calculates the visibility condition for all fields based on the provided input data, context, steps, and blocks.
 *
 * @param {any} inputData - The input data containing the values of the fields.
 * @param {any} context - The invocattion context .
 * @param {Object.<string, string[]>} steps - An object representing the steps and their corresponding keys.
 * @param {Object.<string, InputFieldsType>} blocks - An object representing the blocks and their input field types.
 * @returns {Object.<string, boolean>} - An object where each key represents a field and the value is a boolean indicating whether the visibility condition is met for that field.
 */
export function getVisibilityConditionForAllFields(
  inputData: any,
  context: any,
  steps: { [key: string]: string[] },
  blocks: { [key: string]: InputFieldsType }
) {
  const visibilityConditionObj: { [key: string]: boolean } = {}
  const fieldsExecutionCode = getStringFromUsedVariable(inputData)
  function processData(stepsLocation: string) {
    if (Array.isArray(steps?.[stepsLocation]))
      steps[stepsLocation].forEach((keyName: string) => {
        if (typeof blocks[keyName].visibilityCondition === 'string' && blocks[keyName].visibilityCondition) {
          visibilityConditionObj[keyName] = calculateVisibilityConditionViaCode(
            fieldsExecutionCode,
            blocks[keyName].visibilityCondition as string,
            context
          )
        } else {
          visibilityConditionObj[keyName] = true
        }
        processData(keyName)
      })
  }
  processData('root')
  return visibilityConditionObj
}

// 1. Function to convert from old json to new json
export function convertToNewStructure(array: Array<InputFieldsType>, location: string = 'root', addDependsOn: boolean = true) {
  const result: NewJsonType = {
    steps: {
      [location]: []
    },
    blocks: {}
  }
  function processItem(item: InputFieldsType, includeLocation: boolean) {
    const updatedkey = includeLocation
      ? `${location}.${item.key !== undefined ? item.key : item.value}`
      : `${item.key !== undefined ? item.key : item.value}`
    if (item?.type === 'dictionary') {
      createStepsAndBlocksForDicitionary(result, updatedkey, item)
    } else if (item?.type === 'input groups') {
      result.steps[updatedkey.toString()] = []
      // eslint-disable-next-line
      const { children, fields, ...itemWithoutChildren } = item
      result.blocks[updatedkey] = itemWithoutChildren
      if (itemWithoutChildren.fieldsGenerator) result.blocks[updatedkey].source = itemWithoutChildren.fieldsGenerator
      if (Array.isArray(item?.children)) {
        item?.children?.forEach((child) => {
          const key = `${updatedkey}.${child?.key}`
          result?.steps?.[updatedkey]?.push(key)
          child.key = key
          processItem(child, false)
        })
      } else if (Array.isArray(item?.fields)) {
        item?.fields?.forEach((child) => {
          const key = `${updatedkey}.${child?.key}`
          result?.steps?.[updatedkey]?.push(key)
          child.key = key
          processItem(child, false)
        })
      } else {
        delete result.steps[updatedkey.toString()]
      }
    } else {
      // eslint-disable-next-line
      const { ...itemWithoutChildren } = item
      result.blocks[updatedkey] = itemWithoutChildren
      if (Array.isArray(itemWithoutChildren.options)) result.blocks[updatedkey].children = itemWithoutChildren.options
      if (itemWithoutChildren.optionsGenerator) result.blocks[updatedkey].source = itemWithoutChildren.optionsGenerator
    }
    if (addDependsOn) {
      result.blocks[updatedkey].dependsOn = extractUsedVariables(result.blocks[updatedkey].source, 'context.inputData')
    }
  }
  array?.forEach((item) => {
    result?.steps?.[location]?.push(`${location === 'root' ? '' : `${location}.`}${item.key !== undefined ? item?.key : item?.value}`)
    processItem(item, location !== 'root')
  })
  return result
}

// 2. Function to extract Used variable from the source
// usage(  extractUsedVariables(jsCode, ‘context.inputData’) )
export function extractUsedVariables(jsCode = '', basePrefix = 'context.inputData') {
  jsCode = jsCode.replaceAll('?.', '.')
  const optionalChainingRegexPart = '\\??\\.'
  const prefixRegex = `${basePrefix.replace('.', `(${optionalChainingRegexPart})`)}(${optionalChainingRegexPart})`
  const regex = new RegExp(`${prefixRegex}([a-zA-Z_.][a-zA-Z0-9_.]*)`, 'g')
  const matches = []
  let match
  // eslint-disable-next-line
  while ((match = regex.exec(jsCode)) !== null) {
    matches.push(match[3]) // Adjust index based on number of capture groups in regex
  }
  return [...new Set(matches)]
}

export function extractWholeRequiredFields(json: any) {
  const requiredFields: { [key: string]: string[] } = {}

  function processStep(stepKey: string) {
    requiredFields[stepKey] = []

    // Process each key in the current step
    json.steps[stepKey].forEach((key: string) => {
      const block = json.blocks[key]
      if (block && block.required) {
        requiredFields[stepKey].push(key)
      }
      // Recursive call for nested steps
      if (json.steps[key]) {
        processStep(key)
      }
    })
  }
  // Start processing from the root
  processStep('root')
  return requiredFields
}

export function extractRequiredFields(stepKey: string, stepArr: any, blocksJson: BlockType) {
  const requiredFields: { [key: string]: string[] } = {}

  function processStep(stepKey: string) {
    requiredFields[stepKey] = []
    // Process each key in the current step
    ;(stepArr?.[stepKey] || [])?.forEach((key: string) => {
      const block = blocksJson?.[key]
      if (block && (block.required || block.type === 'input groups' || block.type === 'dictionary')) {
        requiredFields[stepKey].push(key)
      }
      // Recursive call for nested steps
      if (stepArr?.[key] && (block.type === 'input groups' || block.type === 'dictionary')) {
        processStep(key)
      }
    })
  }
  // Start processing from the root
  processStep(stepKey || 'root')
  return requiredFields
}

export const isFieldEnableOrVisibleFunction = (dependsOn: string[], flags: { [key: string]: boolean } = {}) => {
  return dependsOn?.every((key) => {
    return flags?.[key] === true
  })
}

export const getKeysToSetFalseInFlagWhenValueChange = (key: string, usedInJson: { [key: string]: string[] }): string[] => {
  let arrToReturn: string[] = []
  arrToReturn = [...arrToReturn, ...(usedInJson[key] || [])]
  usedInJson[key]?.forEach((keyName) => {
    arrToReturn = [...arrToReturn, ...getKeysToSetFalseInFlagWhenValueChange(keyName, usedInJson)]
  })
  return [...new Set(arrToReturn)]
}

export const getUsedInJsonFromBlocksDependsOn = (blocks: { [key: string]: any }) => {
  const valueToReturn: { [key: string]: any } = {}
  Object.keys(blocks)?.forEach((keyName) => {
    blocks?.[keyName]?.dependsOn?.forEach((keyNameToAppend: string) => {
      if (typeof valueToReturn[keyNameToAppend] === 'object') {
        valueToReturn[keyNameToAppend].add(keyName)
      } else {
        valueToReturn[keyNameToAppend] = new Set([keyName])
      }
    })
  })
  Object.keys(valueToReturn)?.forEach((key) => {
    valueToReturn[key] = Array.from(valueToReturn[key])
  })
  return valueToReturn
}

export function getVales(inputData: any, dependsOnArr?: string[]): string[] {
  const arr: string[] = []
  dependsOnArr?.forEach((element: string) => {
    if (inputData?.[element]) {
      arr.push(inputData[element])
    }
  })
  return arr
}

export function isRequiredFieldsHaveValueTrue(
  requiredJson: { [key: string]: string[] },
  flagJson: { [key: string]: boolean },
  key: string = 'root'
) {
  if (requiredJson[key].length === 0) return true
  return requiredJson[key].every((childKey) => flagJson[childKey] === true)
}

export function isRequiredFieldsHaveValueTrueRecursion(
  requiredJson: { [key: string]: string[] },
  flagJson: { [key: string]: boolean },
  inputData: any,
  context: any,
  steps: { [key: string]: string[] },
  blocks: { [key: string]: InputFieldsType },
  key: string = 'root'
): boolean {
  const visibilityCondition = getVisibilityConditionForAllFields(inputData, context, steps, blocks)
  function processData(key: string = 'root'): boolean {
    if (requiredJson?.[key]?.length === 0) return true
    return requiredJson?.[key]?.every((childKey: string) => {
      if (visibilityCondition[childKey] === false) return true
      const isFlagTrue = blocks[childKey]?.type === 'input groups' ? true : flagJson?.[childKey] === true
      const isNestingchildrenTrue = blocks[childKey]?.type === 'input groups' && requiredJson?.[childKey] ? processData(childKey) : true
      return isFlagTrue && isNestingchildrenTrue
    })
  }
  return processData(key)
}

export function removeGarbageDataFromSelectedValues(
  inputData: { [key: string]: string },
  steps: { [key: string]: string[] },
  blocks: { [key: string]: InputFieldsType },
  context: any
): { [key: string]: string } {
  const newObj: { [key: string]: string } = {}
  const visibilityCondition = getVisibilityConditionForAllFields(inputData, context, steps, blocks)
  // hookUrl performSubscribe
  function processData(location: string) {
    if (Array.isArray(steps?.[location]))
      steps[location]?.forEach((childLocation) => {
        if (visibilityCondition[childLocation] === false) return
        if (Object.prototype.hasOwnProperty.call(inputData, childLocation) && inputData?.[childLocation] !== '') {
          newObj[childLocation] = inputData[childLocation]
          const newUpdatedKey = getKeyWith_ForPluginSelectedValues(childLocation)
          if (inputData[newUpdatedKey]) newObj[newUpdatedKey] = inputData[newUpdatedKey]
          if (inputData[`${newUpdatedKey}-Type`]) newObj[`${newUpdatedKey}-Type`] = inputData[`${newUpdatedKey}-Type`]
        }
        if (inputData[`${getKeyWith_ForPluginSelectedValues(childLocation)}-Type`] === 'dictionary') {
          newObj[`${getKeyWith_ForPluginSelectedValues(childLocation)}-Type`] =
            inputData[`${getKeyWith_ForPluginSelectedValues(childLocation)}-Type`]
        }
        if (steps[childLocation]) {
          processData(childLocation)
        }
      })
  }
  processData('root')
  if (inputData?.hookUrl) newObj.hookUrl = inputData.hookUrl
  if (inputData?.performSubscribe) newObj.performSubscribe = inputData.performSubscribe
  if (inputData?.scheduledTime) newObj.scheduledTime = inputData.scheduledTime
  return newObj
}
export function getKeyWith_ForPluginSelectedValues(keyToUpdate: string) {
  const childLocationSplitArray = keyToUpdate.split('.')
  childLocationSplitArray[childLocationSplitArray.length - 1] = `_${childLocationSplitArray[childLocationSplitArray.length - 1]}`
  return childLocationSplitArray.join('.')
}

export function checkForUniqueName(jsonTovalidate: Array<any>) {
  const uniqueKey = new Set()
  if (Array.isArray(jsonTovalidate)) {
    for (let keyIndex = 0; keyIndex < jsonTovalidate.length; keyIndex++) {
      const keyToCheck = jsonTovalidate[keyIndex].key
      if (!keyToCheck) throw Error(`Please Enter Key at ${jsonTovalidate[keyIndex].label}`)
      if (uniqueKey.has(keyToCheck)) throw Error(`Key already exist in json ${jsonTovalidate[keyIndex].label}`)
      uniqueKey.add(keyToCheck)
      if (Array.isArray(jsonTovalidate[keyIndex].children) && jsonTovalidate[keyIndex].type === 'input groups')
        checkForUniqueName(jsonTovalidate[keyIndex].children)
    }
  }
}

export function removeDataFromStepsAndBlocksUtility(steps: StepType, blocks: BlockType, parentKey: string, key: string) {
  const updatedSteps = removeFromSteps(cloneDeep(steps), key, parentKey)
  const updatedBlocks = removeFromBlocks(updatedSteps, cloneDeep(blocks))
  return {
    steps: updatedSteps,
    blocks: updatedBlocks
  }
}

function removeFromSteps(steps, keyToRemove, location = 'root') {
  // Helper function to recursively remove a key and its sub-keys
  function recursiveRemove(currentKey) {
    if (steps[currentKey]) {
      // If the current key has children, first remove all children
      steps[currentKey].forEach((childKey) => recursiveRemove(childKey))
      // Then delete the current key from the steps
      delete steps[currentKey]
    }
  }
  // Start by recursively removing the key to remove and its children
  recursiveRemove(keyToRemove)
  // Remove the key from its location array if it exists there
  if (steps[location]) {
    steps[location] = steps[location].filter((key) => key !== keyToRemove)
  }
  return steps
}

function removeFromBlocks(steps, blocks) {
  // Helper function to check if a key exists anywhere in the steps object
  const keyExistsInSteps = (key, stepsObj) => {
    const keys = Object.keys(stepsObj)
    for (let i = 0; i < keys.length; i++) {
      const stepKey = keys[i]
      if (stepKey === key) {
        return true
      }
      if (Array.isArray(stepsObj[stepKey])) {
        if (stepsObj[stepKey].includes(key)) {
          return true
        }
      } else if (typeof stepsObj[stepKey] === 'object' && stepsObj[stepKey] !== null) {
        if (keyExistsInSteps(key, stepsObj[stepKey])) {
          return true
        }
      }
    }
    return false
  }

  // Iterating through the blocks object and deleting keys not found in steps
  Object.keys(blocks).forEach((key) => {
    if (!keyExistsInSteps(key, steps)) {
      delete blocks[key]
    }
  })

  return blocks
}

/**
 * Function to get duplicate keys in JSON input for a plugin.
 *
 * @param {object} pluginData - The input plugin data containing JSON structure and selected values.
 * @returns {object} - An object containing an array of duplicate keys and an error message.
 */
export function getDuplicateKeysArrFordictionary(pluginData) {
  const stepsData = pluginData?.inputJson // Extract input JSON data
  const selectedValues = pluginData?.selectedValues // Extract selected values
  const steps = stepsData?.steps // Extract steps from input JSON data
  const blocks = stepsData?.blocks // Extract blocks from input JSON data
  const inputData = selectedValues?.inputData // Extract input data from selected values

  // Initialize objects to track duplicate keys and value mappings
  const duplicateKeyObj: { [key: string]: string[] } = {}
  const valueMap: { [key: string]: { [key: string]: string } } = {}

  if (steps && blocks) {
    Object.keys(steps)?.forEach((stepRootId: string) => {
      steps[stepRootId].forEach((stepId: string) => {
        // Iterate through root steps
        if (blocks[stepId]?.type === 'dictionary') {
          // Check if block type is 'dictionary'
          // Get keys for the current step
          const stepKeys = steps[stepId]?.map((key) => `${key}.key`).filter((fullKey) => blocks[fullKey])

          if (stepKeys) {
            valueMap[stepId] = {} // Initialize value map for current step
            duplicateKeyObj[stepId] = [] // Initialize duplicate key array for current step

            stepKeys.forEach((fullKey) => {
              // Iterate through keys of the current step
              const value = inputData[fullKey] // Get value from input data for the key

              if (value) {
                if (value in valueMap[stepId]) {
                  // If value already exists in the value map, it's a duplicate
                  duplicateKeyObj[stepId].push(fullKey, valueMap[stepId][value])
                } else {
                  // Otherwise, add value to the value map
                  valueMap[stepId][value] = fullKey
                }
              }
            })

            // Ensure duplicates are unique
            duplicateKeyObj[stepId] = [...new Set(duplicateKeyObj[stepId])]
          }
        }
      })
    })
  }

  // Collect all duplicate keys in a single array
  const duplicateKeysArr: string[] = []
  Object.keys(duplicateKeyObj)?.forEach((key) => {
    if (duplicateKeyObj[key]?.length) {
      duplicateKeysArr?.push(...duplicateKeyObj[key])
    }
  })

  // Construct error message for duplicate values
  let errorMessage = 'values: '
  const keys: string[] = []

  Object.values(valueMap).forEach((innerObj) => {
    Object.entries(innerObj).forEach(([key, value]) => {
      if (duplicateKeysArr.includes(value)) {
        keys.push(key)
      }
    })
  })

  errorMessage += keys.join(', ')

  // Return the array of duplicate keys and the error message
  return { keysArr: duplicateKeysArr, errorMessage }
}

export function createStepsAndBlocksForDicitionary(
  result: { steps: { [key: string]: string[] }; blocks: { [key: string]: any } },
  parentKey: string,
  inputField: InputFieldsType,
  steps = []
) {
  const uuid = generateNewId(5)
  result.blocks[`${parentKey}.${uuid}.key`] = { ...inputField.template.key, key: `${parentKey}.${uuid}.key` }
  if (result.blocks[`${parentKey}.${uuid}.key`].option) {
    result.blocks[`${parentKey}.${uuid}.key`].children = result.blocks[`${parentKey}.${uuid}.key`].option
  }
  result.blocks[`${parentKey}.${uuid}.value`] = { ...inputField.template.value, key: `${parentKey}.${uuid}.value` }
  if (result.blocks[`${parentKey}.${uuid}.value`].option) {
    result.blocks[`${parentKey}.${uuid}.value`].children = result.blocks[`${parentKey}.${uuid}.value`].option
  }
  result.blocks[`${parentKey}.${uuid}`] = { type: 'dictionary' }
  result.blocks[`${parentKey}`] = inputField
  result.steps[`${parentKey}.${uuid}`] = [`${parentKey}.${uuid}.key`, `${parentKey}.${uuid}.value`]
  result.steps[`${parentKey}`] = [...steps, `${parentKey}.${uuid}`]
}
