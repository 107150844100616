import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { cardData } from '../../utils/utilities'

// eslint-disable-next-line
const SupportDialog: React.FC<any> = () => {
  return (
    <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }} className='help-options-dialog'>
      {cardData.map((cardDetails: any, index: number) => (
        <Link
          // eslint-disable-next-line
          key={index}
          to={cardDetails.url}
          target='_blank'
          className='no-textdecoration'
          style={{ display: 'grid', height: cardDetails.height, gridRow: cardDetails.cssGridRowValue }}
        >
          <Card
            className='border-none card-data flex-center-center text-center border-radius-16'
            sx={{
              backgroundColor: cardDetails.bgColor,
              '&:hover': {
                boxShadow: '10px 10px 20px rgba(184, 176, 160, .4) !important'
              }
            }}
          >
            <CardContent className='py-0 flex-col-center-center row-gap-6'>
              <img src={cardDetails.imgSrc} alt={cardDetails.imgAlt} style={{ width: cardDetails.imgWidth || '60px' }} />
              <Typography variant='h6' fontWeight='bold' sx={{ fontSize: cardDetails.textSize }}>
                {cardDetails.title}
              </Typography>
              <Typography className='font-12'>{cardDetails.description}</Typography>
            </CardContent>
          </Card>
        </Link>
      ))}
    </Box>
  )
}

export default SupportDialog
