import { createAsyncThunk } from '@reduxjs/toolkit'
import cloneDeep from 'lodash.clonedeep'
import { batch } from 'react-redux'
import { getOneScripts, updateFlow, updateStepOrder, createOrUpdateTrigger, getOneFunction, createStep, deleteStep } from '../../api'
import { updateAppInfoState } from '../appInfo/appInfoSlice.ts'
import { errorToast } from '../../components/customToast'
import { handleDeleteStepsV2, updatedUsedVariables } from '../invocationV2/invocationSliceV2.ts'
import { BlockTypes, LOCAL_NOTFICATION_REQUIRED_FIELDS, MiscTypes, STEP_OPERATION_STATUS, Tabnames } from '../../enums'
import {
  addNewStepToStepData,
  deleteScriptFromStepReducer,
  deleteStepFromStepData,
  setAuthFieldsStart,
  setHasUnsavedCode,
  setSelectedActionDetails
} from '../stepsV2/stepSliceV3.ts'
import { generateNewId, getInfoParamtersFromUrl, getNextUntitledName } from '../../utils/utilities'
import { getApiInstanceDataFromCode } from '../stepsV2/stepReducerV3.ts'
import { getParsedPluginFieldsInputData } from '../plugin/currentSelectedPlugin/utility.ts'
import {
  getContextFromInvocation,
  isRequiredFieldsHaveValueTrueRecursion,
  removeGarbageDataFromSelectedValues
} from '../../components/plugin/pluginUtils/plugin_utility.ts'
import { addScriptAndUpdateList, addScriptInRedux } from '../scripts/scriptsSlice'
import { DH_AUTH_TYPES } from '../../pages/developerHub/constants/developerHubConstants.ts'
import { setLocalNotification } from '../notification/notificationSlice'
import config from '../../config'

export const setFlowJsonThunk = createAsyncThunk('flowJsonV2/setFlowJsonThunk', async (payload: any, { dispatch }) => {
  const type = payload.type || MiscTypes.FLOW
  const data = await getOneScripts(payload?.functionId, payload?.projectId, type)
  dispatch(updatedUsedVariables(data.data.data.used_variables))
  dispatch(addScriptInRedux(data.data.data))
  return data.data.data
})

export const handleDeleteStepsThunk = createAsyncThunk('flowJson/handleDeleteStepsThunk', async (payload, { dispatch, getState }) => {
  const { type, slugName, stepId } = payload
  const { scriptId } = getInfoParamtersFromUrl()
  const flowJsonBlocks = getState()?.flowJsonV2?.[scriptId]?.draftedFlowJson?.blocks
  let body
  if (
    [BlockTypes.API, BlockTypes.FUNCTION, BlockTypes.PLUG, BlockTypes.VARIABLE].includes(type) &&
    flowJsonBlocks?.[slugName]?.status !== STEP_OPERATION_STATUS.DRAFTED
  ) {
    const title = getNextUntitledName(flowJsonBlocks, `${slugName}_DELETE`)
    body = { title }
  }

  const response = await deleteStep(scriptId, slugName, body)

  dispatch(deleteStepFromStepData({ stepId }))
  if ([BlockTypes.FUNCTION, BlockTypes.API, BlockTypes.PLUG].includes(type)) {
    dispatch(handleDeleteStepsV2({ payload: { key: slugName }, type: 'apiFunctionPluginComponent' }))
  } else if (type === BlockTypes.VARIABLE) {
    dispatch(handleDeleteStepsV2({ payload: { key: slugName }, type: BlockTypes.VARIABLE }))
  }

  dispatch(addScriptAndUpdateList(response))

  return response
})

export const publishFlowThunk = createAsyncThunk('flowJson/publishFlowThunk', async (payload, { dispatch, getState }) => {
  const scriptDetails = getState()?.scripts?.scripts?.[payload?.scriptId]
  if (
    payload?.state !== 'revert' &&
    scriptDetails.json_script?.trigger.triggerType === 'webhook' &&
    (!scriptDetails?.title || !scriptDetails?.metadata?.description)
  ) {
    errorToast('Please provide name and description.')
    throw new Error('')
  }
  const response = await updateFlow({ projectId: payload.projectId, scriptId: payload.scriptId, state: payload.state })
  dispatch(
    addScriptAndUpdateList({
      ...scriptDetails,
      json_script: response.draft_json_script,
      ...response,
      ...(payload?.state === 'revert' ? { used_variables: scriptDetails?.published_used_variables } : {})
    })
  )
  dispatch(deleteScriptFromStepReducer({}))
  return response
})

export const createOrUpdateTriggerThunk = createAsyncThunk('flowJson/triggerThunk', async (payload, { getState, dispatch }) => {
  const { scriptId, orgId, projectId, stepId, tabName } = getInfoParamtersFromUrl()
  const { navigate, stepId: stepIdFromPayload } = payload
  let dataToSend
  const flowJson = getState()?.flowJsonV2[scriptId]?.flowJson
  if (payload?.triggerType === 'cron') {
    dataToSend = {
      ...payload,
      statement: payload?.statement,
      id: scriptId,
      triggerType: payload?.triggerType,
      type: flowJson?.cron?.cron_job_id ? 'update' : 'add',
      ...(flowJson?.cron?.cron_job_id && { cron_id: flowJson.cron.cron_job_id })
    }
  }
  if (payload?.triggerType === 'trigger') {
    const triggerData = getState()?.stepsDataV3?.[scriptId]?.[stepIdFromPayload || stepId || 'SET_TRIGGER']?.[tabName]
    const contextViaInvocation = getContextFromInvocation(getState()?.invocationV2?.[scriptId])
    const selectedValues = cloneDeep(triggerData?.pluginData?.selectedValues)

    // const trigger = flowJson?.trigger
    if (
      !isRequiredFieldsHaveValueTrueRecursion(
        triggerData?.requiredFieldsList,
        triggerData?.flags,
        selectedValues?.inputData,
        contextViaInvocation,
        triggerData?.pluginData?.inputJson?.steps,
        triggerData?.pluginData?.inputJson?.blocks
      )
    ) {
      dispatch(setLocalNotification({ [`${stepIdFromPayload || stepId}.${LOCAL_NOTFICATION_REQUIRED_FIELDS.address}`]: { show: true } }))
      throw new Error('Please fill all required fields!')
    }
    const newInputData = removeGarbageDataFromSelectedValues(
      selectedValues?.inputData,
      triggerData?.pluginData?.inputJson?.steps,
      triggerData?.pluginData?.inputJson?.blocks,
      contextViaInvocation
    )
    selectedValues.inputData = cloneDeep(newInputData)
    const newJson = cloneDeep(flowJson)

    if (payload?.type === 'add') {
      dataToSend = {
        ...triggerData?.pluginData,
        selectedValues: selectedValues,
        type: payload?.type,
        triggerType: triggerData?.pluginData?.triggerType || 'hook',
        name: 'trigger'
      }
    }
    if (payload.type === 'update') {
      dataToSend = {
        ...triggerData?.pluginData,
        selectedValues: selectedValues,
        type: payload?.type,
        triggerType: triggerData?.pluginData?.triggerType,
        name: 'trigger',
        url: triggerData?.pluginData?.url
      }
    }

    newJson.trigger = dataToSend
  }

  if (payload?.triggerType === 'webhook') {
    dataToSend = {
      ...payload
    }
  }
  if (payload?.triggerType === 'email') {
    dataToSend = {
      ...payload
    }
  }
  const response = await createOrUpdateTrigger(scriptId, dataToSend)

  if (response?.data?.success === false) {
    throw new Error(response?.response?.data?.message)
  }
  batch(() => {
    dispatch(setHasUnsavedCode({ stepId: stepIdFromPayload || stepId, hasUnsavedCode: false }))
    dispatch(addScriptAndUpdateList(response.data.data))
    if (payload?.triggerType !== flowJson?.trigger?.triggerType) {
      dispatch(deleteStepFromStepData({ stepId: 'CRON_PRE_PROCESS' }))
      dispatch(deleteStepFromStepData({ stepId: 'SET_TRIGGER' }))
    }
  })

  /* eslint-disable */
  const triggerService = response?.data?.data?.json_script?.trigger?.serviceId || response?.data?.data?.json_script?.trigger?.triggerType
  if (!window.location.pathname.startsWith('/integrations'))
    navigate(`/projects/${orgId}/${projectId}/service/${triggerService}${config.workflowBaseUrl}/${scriptId}/${Tabnames.DRAFT}`)
  return response.data.data
})

export const updateStepOrderThunk = createAsyncThunk('flowJson/updateStepOrderThunk', async (payload, { dispatch }) => {
  const { scriptId } = getInfoParamtersFromUrl()
  const dataToSend = {
    order: {
      ...payload?.order
    }
  }
  const data = await updateStepOrder(scriptId, dataToSend)
  dispatch(addScriptInRedux(data))
  return data
})

export const createDraftStepThunk = createAsyncThunk('flowJson/createDraftStepThunk', async (payload, { dispatch }) => {
  const { scriptId } = getInfoParamtersFromUrl()
  const { identifier, orderGroup, block, position, slugName, ifBlockData, aistep } = payload
  const blockDetails = block[slugName] || block[identifier]
  const dataToSend = {
    type: blockDetails.type,
    title: slugName,
    orderGroup,
    position,
    stepId: identifier,
    data: blockDetails?.type === BlockTypes.COMMENT ? blockDetails?.data : blockDetails?.metaData,
    iconUrl: blockDetails?.url,
    aistep
  }
  if (blockDetails?.type === BlockTypes.IFGROUP) {
    const childIfBlockDetails = ifBlockData?.ifBlockToAdd?.[ifBlockData?.ifBlockSlugName]
    dataToSend['stepChildDetails'] = {
      type: childIfBlockDetails?.type,
      title: ifBlockData?.ifBlockSlugName,
      orderGroup: identifier,
      stepId: childIfBlockDetails?.identifier
    }
  }
  const data = await createStep(scriptId, dataToSend)
  dispatch(addScriptAndUpdateList(data))
  return data
})

export const duplicateStepThunk = createAsyncThunk('flowJson/duplicateStepThunk', async (payload, { dispatch, getState }) => {
  const { scriptId } = getInfoParamtersFromUrl()
  const { stepId, setSearchParams, title, orderGroup } = payload
  const stepData = getState()?.stepsDataV3?.[scriptId]?.[payload?.stepId]?.draft
  const flowJsonBlocks = getState()?.flowJsonV2?.[scriptId]?.flowJson?.blocks
  let stepInstance = cloneDeep(stepData)
  let dataToSend = {}
  if (!stepInstance) {
    const originalData: any = await getOneFunction({
      scriptId,
      functionId: stepId
    })
    const { type, title, code, id } = originalData.data.data
    if (type.includes('function')) {
      stepInstance = { type, title, code, id }
    }
    if (type === BlockTypes.API) {
      stepInstance = { type, title, id, ...getApiInstanceDataFromCode(code) }
    }
    if (type === BlockTypes.PLUG) {
      stepInstance = { type, title, id, pluginData: { ...getParsedPluginFieldsInputData(JSON.parse(code)) } }
    }
  }
  if (stepInstance?.type === BlockTypes.PLUG) dataToSend = { metaData: stepInstance.pluginData, url: stepInstance?.pluginData?.iconUrl }
  const name = getNextUntitledName(flowJsonBlocks, `${title}_Duplicate`)
  stepInstance.title = name
  const finalId = `func${generateNewId()}`
  const blockToAdd = {
    [name]: {
      type: stepInstance?.type,
      status: STEP_OPERATION_STATUS.DRAFTED,
      identifier: finalId,
      ...dataToSend
    }
  }
  batch(() => {
    dispatch(updateAppInfoState({ currentStepType: stepInstance?.type }))
    dispatch(addNewStepToStepData({ dataToUpdate: stepInstance, stepId: finalId }))
    dispatch(createDraftStepThunk({ identifier: finalId, orderGroup, block: blockToAdd, setSearchParams, slugName: name }))
  })
  setSearchParams({ stepId: finalId, slugName: name })
})

export const addActionOrTriggerThunk = createAsyncThunk('flowJson/addActionOrTriggerThunk', async (payload, { dispatch, getState }) => {
  const { actionDetails, clientGeneratedId, position, setSearchParams, parent, isIntervention } = payload
  const pluginData = getState().allPlugins.pluginData
  if (!actionDetails) return
  const pluginIcon = actionDetails?.iconurl
  const {
    perform,
    performlist,
    performsubscribe,
    performunsubscribe,
    modifytriggerdata,
    transferoption,
    type,
    triggertype,
    inputjson,
    rowid,
    name,
    actionversionrecordid,
    pluginrecordid,
    pluginname,
    headersandqueryparams,
    authtype,
    iconurl,
    brandcolor,
    havestaticip
  } = actionDetails

  const actionType =
    actionDetails.type === 'custom_action'
      ? BlockTypes.API
      : actionDetails.type === BlockTypes.TRIGGER
      ? BlockTypes.TRIGGER
      : BlockTypes.PLUG

  const pluginDataFromAction = {
    ...(actionDetails?.type === 'trigger'
      ? { perform: perform, isDrafted: true, triggerType: actionDetails?.triggertype }
      : { source: perform }),
    performList: performlist,
    performSubscribe: performsubscribe,
    performUnsubscribe: performunsubscribe,
    modifyTriggerData: modifytriggerdata,
    transferOption: transferoption,
    triggerType: triggertype,
    brandcolor,
    type,
    havestaticip,
    selectedCreate: rowid,
    actionName: name?.trim()?.replace(/[^a-zA-Z0-9]/g, '_'),
    actionVersionId: actionversionrecordid,
    serviceId: pluginrecordid,
    serviceName: pluginname,
    iconUrl: iconurl,
    inputFields: inputjson?.inputFields,
    inputJson: { steps: inputjson?.steps, blocks: inputjson?.blocks },
    authIdLookup: actionDetails?.authidlookup,
    authType: authtype,
    selectedValues: {
      authData: {},
      inputData: triggertype === 'polling' ? { scheduledTime: '15' } : {}
    },
    ...(actionDetails?.type === 'custom_action' ? { queryParamsAndHeaders: headersandqueryparams } : {})
  }
  if (authtype === DH_AUTH_TYPES.noAuth) {
    pluginDataFromAction.selectedValues.authData = { id: DH_AUTH_TYPES.noAuth, type: DH_AUTH_TYPES.noAuth }
  }
  dispatch(
    setSelectedActionDetails({
      pluginDataFromAction,
      stepId: actionType === BlockTypes.TRIGGER ? 'SET_TRIGGER' : clientGeneratedId,
      tabName: 'draft'
    })
  )

  dispatch(updateAppInfoState({ currentStepType: actionType }))
  if (actionDetails) {
    dispatch(
      setAuthFieldsStart({
        filterField: 'pluginrecordid',
        plugId: `'${pluginrecordid}'`,
        tableName: 'auth',
        secondFilterField: 'rowid',
        authId: `'${actionDetails?.authidlookup}'`,
        setSearchParams: setSearchParams,
        pluginIcon: pluginIcon,
        orderGroup: parent,
        stepId: clientGeneratedId,
        actionDetails: actionDetails,
        position,
        availableData: pluginData,
        stepType: actionType,
        isIntervention
      })
    )
  }
})
