import { ActionReducerMapBuilder, SliceCaseReducers, ValidateSliceCaseReducers, current } from '@reduxjs/toolkit'
import { NoInfer } from 'react-redux'
import cloneDeep from 'lodash.clonedeep'
import {
  getContextFromInvocation,
  getUsedInJsonFromBlocksDependsOn,
  removeDataFromStepsAndBlocksUtility,
  removeGarbageDataFromSelectedValues
} from '../../components/plugin/pluginUtils/plugin_utility.ts'
import { BlockTypes, Tabnames } from '../../enums'
import { PluginResponseType } from '../../types/Plugin.ts'
import { $StepsReduxTypeV3 } from '../../types/reduxCore.ts'
import actionType from '../../types/utility.ts'
import removeItemOnceFromArray from '../../utils/arrayUtility'
import isEqual from '../../utils/deepCheckSelector'
import {
  addDummyDraftData,
  compareValuesLodash,
  convertArrToString,
  fetchUsedVariables,
  getRawType,
  getStringToArrayObject,
  makeUrl,
  urlWithParams
} from '../../utils/utilities'
import { initialStatePlugin } from '../enum.ts'
import { getParsedPluginFieldsInputData } from '../plugin/currentSelectedPlugin/utility.ts'
import { setBodyTypeAndValue } from './stepSelectorV2.ts'
import { createOrUpdateFunctionThunk, setFunctionJsonThunkV3 } from './stepThunkV2'

export const initialState: $StepsReduxTypeV3 = {}
export const reducers: ValidateSliceCaseReducers<$StepsReduxTypeV3, SliceCaseReducers<$StepsReduxTypeV3>> = {
  setKeyValueInstance(state, action: actionType<any>) {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = action.urlData
    const { id } = action.payload // here id is stepId
    const currentTab = tabName === Tabnames.PUBLISH ? Tabnames.PUBLISH : Tabnames.DRAFT

    if (!state[sectionIdOrScriptId]?.[versionIdOrStepId]) {
      state[sectionIdOrScriptId] = {
        ...state[sectionIdOrScriptId],
        [versionIdOrStepId]: {
          [currentTab]: {
            ...action?.payload,
            usedVariables: getUsedVariables(action?.payload)
          }
        }
      }
    }
    const tempInstance = cloneDeep(state?.[sectionIdOrScriptId]?.[action?.payload?.stepId || versionIdOrStepId]?.[currentTab])
    if (state?.[sectionIdOrScriptId]?.[versionIdOrStepId]) {
      state[sectionIdOrScriptId][id || versionIdOrStepId] = {
        ...state[sectionIdOrScriptId][id || versionIdOrStepId],
        [currentTab]: {
          ...tempInstance,
          ...action.payload,
          ...(['api', 'function']?.includes(tempInstance?.type)
            ? { usedVariables: getUsedVariables({ ...tempInstance, ...action?.payload }) }
            : {})
        },
        hasUnsavedCode:
          action.payload?.hasUnsavedCode === false
            ? state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.hasUnsavedCode || false
            : state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.hasUnsavedCode ||
              !compareValuesLodash(state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.draft, action.payload)
      }
    }
  },
  setDryRunResponseForStep(state, action: actionType<any>) {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = action.urlData
    const { dataToUpdate } = action.payload
    try {
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].result = dataToUpdate
    } catch (error) {
      console.error(error)
    }
  },
  setApiInStep(state, action: actionType<any>) {
    const { sectionIdOrScriptId, versionIdOrStepId } = action.urlData
    const { dataToUpdate, stepId } = action.payload
    const data = addDummyDraftData(dataToUpdate)
    state[sectionIdOrScriptId] = {
      ...state[sectionIdOrScriptId],
      [stepId || versionIdOrStepId]: {
        draft: { ...data, isLoading: false },
        publish: { ...data, isLoading: false },
        isLoading: false,
        hasUnsavedCode: false
      }
    }
  },
  addNewStepToStepData(state, action: actionType<any>) {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = action.urlData
    const { dataToUpdate, stepId } = action.payload
    const data = addDummyDraftData(dataToUpdate)
    state[sectionIdOrScriptId] = {
      ...state[sectionIdOrScriptId],
      [stepId || versionIdOrStepId]: {
        ...state?.[sectionIdOrScriptId]?.[stepId || versionIdOrStepId],
        [tabName]: { ...data, ...state?.[sectionIdOrScriptId]?.[stepId || versionIdOrStepId]?.[tabName] },
        isLoading: false,
        hasUnsavedCode: false
      }
    }
  },
  deleteStepFromStepData(state, action: actionType<any>) {
    const { sectionIdOrScriptId } = action.urlData
    const { stepId } = action.payload
    delete state?.[sectionIdOrScriptId]?.[stepId]
  },
  deleteScriptFromStepReducer(state, action) {
    const { sectionIdOrScriptId } = action.urlData
    delete state?.[sectionIdOrScriptId]
  },
  setHeadersFieldV2(state, action: actionType<any>) {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName: currentTab } = action.urlData
    const tabName = currentTab === Tabnames.PUBLISH ? Tabnames.PUBLISH : Tabnames.DRAFT
    const stepInstance = state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]
    if (stepInstance?.requestBodyType !== '-1') {
      const text = stepInstance?.headers?.toString()
      const arrtext =
        getStringToArrayObject(2, text)?.filter((ele) => {
          return ele.name !== 'Content-Type' && ele.name !== 'content-type' && (ele.name || ele.value)
        }) || []
      if (stepInstance?.requestBodyType === '0' && stepInstance?.bodyFormData?.[0]) {
        state[sectionIdOrScriptId][versionIdOrStepId][tabName].headers = convertArrToString([
          ...arrtext,
          { name: 'Content-Type', value: 'multipart/form-data' }
        ])
      } else if (stepInstance?.requestBodyType === '1' && stepInstance?.bodyFormUrlencoded?.[0]) {
        state[sectionIdOrScriptId][versionIdOrStepId][tabName].headers = convertArrToString([
          ...arrtext,
          { name: 'Content-Type', value: 'application/x-www-form-urlencoded' }
        ])
      } else if (stepInstance?.requestBodyType === '2') {
        state[sectionIdOrScriptId][versionIdOrStepId][tabName].headers = convertArrToString([
          ...arrtext,
          { name: 'Content-Type', value: 'application/json' }
        ])
      } else if (stepInstance?.requestBodyType === '3') {
        state[sectionIdOrScriptId][versionIdOrStepId][tabName].headers = convertArrToString([
          ...arrtext,
          { name: 'Content-Type', value: getRawType(stepInstance.bodyRawScriptType) }
        ])
      } else {
        state[sectionIdOrScriptId][versionIdOrStepId][tabName].headers = convertArrToString([...arrtext])
      }
    }
  },

  setHasUnsavedCode(state, action) {
    try {
      const { sectionIdOrScriptId } = action.urlData
      const { stepId, hasUnsavedCode } = action.payload
      if (state[sectionIdOrScriptId][stepId]) state[sectionIdOrScriptId][stepId] = { ...state[sectionIdOrScriptId][stepId], hasUnsavedCode }
    } catch (error) {
      console.error(error)
    }
  },

  // ======== PLUGIN ==========
  resetCurrentSelectedPlugin: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    state[sectionIdOrScriptId] = {
      ...state[sectionIdOrScriptId],
      [payload.payload.stepId || versionIdOrStepId]: {
        [payload?.payload?.tabName || tabName]: { ...cloneDeep(initialStatePlugin) }
      }
    }
    const dataToUpdate = payload?.payload?.dataToUpdate
    if (dataToUpdate)
      state[sectionIdOrScriptId][payload.payload.stepId || versionIdOrStepId][payload?.payload?.tabName || tabName].pluginData = {
        ...state[sectionIdOrScriptId][payload.payload.stepId || versionIdOrStepId][payload?.payload?.tabName || tabName].pluginData,
        ...dataToUpdate
      }
  },
  setSelectedActionDetails: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    const target = cloneDeep(
      state?.[sectionIdOrScriptId]?.[payload.payload.stepId || versionIdOrStepId]?.[payload.payload.tabName || tabName]?.pluginData
    )
    const dataToAppend = cloneDeep(payload.payload.pluginDataFromAction)

    if (target?.selectedValues?.inputData && Object.keys(target?.selectedValues?.inputData)?.length) {
      dataToAppend.selectedValues = {
        ...dataToAppend.selectedValues,
        inputData: target?.selectedValues?.inputData
      }
    }
    if (target?.selectedValues?.authData && Object.keys(target?.selectedValues?.authData)?.length) {
      dataToAppend.selectedValues = {
        ...dataToAppend.selectedValues,
        authData: target?.selectedValues?.authData
      }
    }
    state[sectionIdOrScriptId] = {
      ...state?.[sectionIdOrScriptId],
      [payload?.payload?.stepId || versionIdOrStepId]: {
        ...state?.[sectionIdOrScriptId]?.[payload.payload.stepId || versionIdOrStepId],
        ...(payload?.payload?.hasUnsavedCode === false ? { hasUnsavedCode: false } : {}),
        [payload?.payload?.tabName || tabName]: {
          ...cloneDeep(initialStatePlugin),
          ...state?.[sectionIdOrScriptId]?.[payload?.payload?.stepId || versionIdOrStepId]?.[payload?.payload?.tabName || tabName],
          pluginData: {
            ...target,
            ...dataToAppend
          },
          iconUrl: payload?.payload?.iconUrl
        }
      }
    }
  },
  setPayloadInPluginInfoAirtable: (state, payload) => {
    // deprecate after developer hub
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginInfoAirtable = {
      ...state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginInfoAirtable,
      ...payload.payload
    }
  },
  clearInputJson: (state, payload) => {
    const { sectionIdOrScriptId, tabName, versionIdOrStepId } = payload.urlData
    const fieldKey = payload?.payload?.fieldKey
    const inputData = cloneDeep(state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData?.selectedValues?.inputData)
    const steps = cloneDeep(state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData?.inputJson?.steps)
    const blocks = cloneDeep(state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData?.inputJson?.blocks)
    function recursiveDeletion(keyToRemove) {
      const arrayOfChilds = steps[keyToRemove]
      if (!arrayOfChilds) return
      arrayOfChilds.forEach((childKey) => {
        recursiveDeletion(childKey)
        delete inputData[childKey]
        delete steps[childKey]
        delete blocks[childKey]
      })
    }
    if (steps && fieldKey in steps) {
      recursiveDeletion(fieldKey)
      steps[fieldKey] = []

      state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData.inputJson.steps = steps
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData.inputJson.blocks = blocks
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData.selectedValues.inputData = inputData
    }
  },
  setPluginInputJson: (state, payload: actionType<PluginResponseType>) => {
    const { sectionIdOrScriptId, tabName, versionIdOrStepId } = payload.urlData
    if (!state?.[sectionIdOrScriptId]?.[payload?.payload?.stepId || versionIdOrStepId]?.[payload?.payload?.tabName || tabName]) {
      state[sectionIdOrScriptId] = {
        ...state?.[sectionIdOrScriptId],
        [payload?.payload?.stepId || versionIdOrStepId]: {
          ...state?.[sectionIdOrScriptId]?.[payload?.payload?.stepId || versionIdOrStepId],
          [payload?.payload?.tabName || tabName]: {
            pluginData: {
              inputJson: {
                steps: {},
                blocks: {}
              }
            },
            usedInVariable: {}
          }
        }
      }
    }
    const target = state[sectionIdOrScriptId][payload.payload.stepId || versionIdOrStepId][payload.payload.tabName || tabName].pluginData

    const inputJson = {
      ...target?.inputJson,
      steps: { ...(target?.inputJson?.steps || {}), ...payload.payload?.steps },
      blocks: { ...(target?.inputJson?.blocks || {}), ...payload.payload?.blocks }
    }
    const usedInVariable = getUsedInJsonFromBlocksDependsOn(inputJson.blocks)
    state[sectionIdOrScriptId][payload.payload.stepId || versionIdOrStepId][payload.payload.tabName || tabName].usedInVariable =
      usedInVariable
    state[sectionIdOrScriptId][payload.payload.stepId || versionIdOrStepId][payload.payload.tabName || tabName].pluginData = {
      ...target,
      inputJson
    }
  },
  deleteKeyInBlocksOfInputJson: (state, action: actionType<{ keyToRemove: string }>) => {
    const { sectionIdOrScriptId, tabName, versionIdOrStepId } = action.urlData
    const { keyToRemove } = action.payload
    if (state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson?.blocks?.[keyToRemove])
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData.inputJson.blocks[keyToRemove] = undefined
  },
  deleteDataInInputJson: (state, action: actionType<{ parentKey: string; currentValue: string }>) => {
    const { sectionIdOrScriptId, tabName, versionIdOrStepId } = action.urlData
    // const target = state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson
    const { parentKey, currentValue } = action.payload
    // let jsonToReturn = { ...target }
    if (state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson?.steps?.[parentKey]) {
      if (
        state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson?.steps?.[parentKey]?.includes(
          `${parentKey}.${currentValue}`
        )
      ) {
        const index = state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson?.steps?.[parentKey]?.indexOf(
          `${parentKey}.${currentValue}`
        )
        if (index > -1) {
          // only splice array when item is found
          state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson?.steps?.[parentKey]?.splice(index, 1) // 2nd parameter means remove one item only
        }
        if (state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson?.steps?.[`${parentKey}.${currentValue}`]) {
          delete state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson?.steps?.[`${parentKey}.${currentValue}`]
        }
      }
    }
    if (state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson?.blocks[`${parentKey}.${currentValue}`]) {
      delete state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData?.inputJson?.blocks[`${parentKey}.${currentValue}`]
    }
  },

  setAuthData: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    const prevAuthData = cloneDeep(state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData?.selectedValues?.authData)
    if (state[sectionIdOrScriptId][versionIdOrStepId][tabName]?.pluginData?.selectedValues)
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData.selectedValues.authData = payload?.payload
    const prevHasunsavedCode = state[sectionIdOrScriptId][versionIdOrStepId].hasUnsavedCode
    state[sectionIdOrScriptId][versionIdOrStepId].hasUnsavedCode = prevHasunsavedCode || !isEqual(prevAuthData, payload?.payload)
  },
  // INPUT FIELDS OPERATION
  setValueOnKey: (state: any, action: any) => {
    const { sectionIdOrScriptId, versionIdOrStepId: urlDataStepId, tabName } = action.urlData
    const { stepId } = action.payload
    const versionIdOrStepId = stepId || urlDataStepId
    const prevData = cloneDeep(state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData?.selectedValues?.inputData)
    const steps = cloneDeep(state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData?.inputJson?.steps)
    const blocks = cloneDeep(state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData?.inputJson?.blocks)
    const cloneDeepPayload = cloneDeep(action.payload)
    delete cloneDeepPayload?.stepId
    const newData = { ...prevData, ...cloneDeepPayload }
    // filter data to remove garbage/drafted data
    if (state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]) {
      const newInputDataForUsedVariable = removeGarbageDataFromSelectedValues(
        newData,
        steps,
        blocks,
        getContextFromInvocation(state?.invocationV2?.[sectionIdOrScriptId])
      )
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].usedVariables = fetchUsedVariables(JSON.stringify(newInputDataForUsedVariable))

      state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData.selectedValues.inputData = newData

      const prevHasUnsavedCode = state[sectionIdOrScriptId][versionIdOrStepId].hasUnsavedCode
      state[sectionIdOrScriptId][versionIdOrStepId].hasUnsavedCode = prevHasUnsavedCode || !compareValuesLodash(prevData, newData)
    }
  },
  // need To remove @remove
  updateRequiredFieldsValue: (state, payload: actionType<{ key: string; addKeyElseRemove: boolean }>) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    if (
      payload.payload.addKeyElseRemove &&
      !state[sectionIdOrScriptId][versionIdOrStepId][tabName].requiredFieldsList.includes(payload.payload.key)
    ) {
      // ADD KEY IF DONT EXIST
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].requiredFieldsList = [
        ...state[sectionIdOrScriptId][versionIdOrStepId][tabName].requiredFieldsList,
        payload.payload.key
      ]
    } else if (state[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.requiredFieldsList?.includes(payload.payload.key)) {
      // REMOVE KEY IF EXIST
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].requiredFieldsList = removeItemOnceFromArray(
        state[sectionIdOrScriptId][versionIdOrStepId][tabName]?.requiredFieldsList,
        payload.payload.key
      )
    }
  },

  updateFlags: (state, action: actionType<{ [key: string]: boolean }>) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = action?.urlData
    state[sectionIdOrScriptId][versionIdOrStepId][tabName].flags = {
      ...state[sectionIdOrScriptId][versionIdOrStepId][tabName].flags,
      ...action?.payload
    }
  },
  updateSingleInputJson: (state, action: actionType<{ jsonKey: string; jsonKeyFields: any }[]>) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = action?.urlData
    action.payload.forEach((fieldsToUpdate) => {
      if (state[sectionIdOrScriptId][versionIdOrStepId][tabName]?.pluginData?.inputJson?.blocks?.[fieldsToUpdate?.jsonKey]) {
        state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData.inputJson.blocks[fieldsToUpdate?.jsonKey] = {
          ...state[sectionIdOrScriptId][versionIdOrStepId][tabName]?.pluginData?.inputJson?.blocks?.[fieldsToUpdate?.jsonKey],
          ...fieldsToUpdate.jsonKeyFields
        }
      }
    })
  },
  updateRequiredFieldList: (state, action: actionType<{ [key: string]: Array<string> }>) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = action?.urlData
    state[sectionIdOrScriptId][versionIdOrStepId][tabName].requiredFieldsList = {
      ...state[sectionIdOrScriptId][versionIdOrStepId][tabName].requiredFieldsList,
      ...action?.payload
    }
  },
  setAuthFieldsStart: () => {
    // const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    // state[sectionIdOrScriptId][versionIdOrStepId || "NEW_PLUGIN"][tabName || 'draft'].pluginInfoAirtable.isLoading = true
  },
  successVerifyAuth: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    state[sectionIdOrScriptId][versionIdOrStepId][tabName].isAuthLoading = false
  },
  startVerifyAuth: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    state[sectionIdOrScriptId][versionIdOrStepId][tabName].isAuthLoading = true
  },
  // BUTTON ACTIONS
  startDryRunSource: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    if (state[sectionIdOrScriptId][versionIdOrStepId][tabName].buttonStatus[payload.payload?.parentKey]?.isLoading)
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].buttonStatus[payload.payload?.parentKey].isLoading = true
    else state[sectionIdOrScriptId][versionIdOrStepId][tabName].buttonStatus[payload.payload?.parentKey] = { isLoading: true }
  },
  setPluginData: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData = {
      ...state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData,
      ...payload?.payload
    }
  },
  errorSourceDryRun: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    // addChildrenInJson(payload.payload?.parentKey?.split('.'), state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginInputJson.operation.inputFields, payload.payload?.children)
    if (state[sectionIdOrScriptId][versionIdOrStepId][tabName]?.buttonStatus?.[payload.payload?.parentKey]?.isLoading)
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].buttonStatus[payload.payload?.parentKey].isLoading = false
    else state[sectionIdOrScriptId][versionIdOrStepId][tabName].buttonStatus[payload.payload?.parentKey] = { isLoading: false }
  },
  startDryRun: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    state[sectionIdOrScriptId][versionIdOrStepId][tabName].buttonStatus.dryRunButtonState.isLoading = true
  },
  startSave: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    state[sectionIdOrScriptId][versionIdOrStepId][tabName].buttonStatus.saveButtonState.isLoading = true
  },
  successDryRun: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    state[sectionIdOrScriptId][versionIdOrStepId][tabName].buttonStatus.dryRunButtonState.isLoading = false
  },
  successSave: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    state[sectionIdOrScriptId][versionIdOrStepId][tabName].buttonStatus.saveButtonState.isLoading = false
  },
  startIsVerify: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    state[sectionIdOrScriptId][versionIdOrStepId][tabName].isVerify = false
  },
  successIsVerify: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName = 'draft' } = payload.urlData
    if (sectionIdOrScriptId && versionIdOrStepId) {
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].isVerify = true
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].isDynamicAuth = false
    }
  },
  setIsVerify: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    state[sectionIdOrScriptId][versionIdOrStepId][tabName].isVerify = payload.payload
  },

  // optional fields visibility
  updateOptinalFieldVisibility: (state, payload: actionType<{ key: string; value: boolean }>) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    if (state[sectionIdOrScriptId][versionIdOrStepId][tabName].optionalFieldVisibility)
      state[sectionIdOrScriptId][versionIdOrStepId][tabName].optionalFieldVisibility[payload.payload.key] = payload.payload.value
    else
      state[sectionIdOrScriptId][versionIdOrStepId][tabName] = { optionalFieldVisibility: { [payload.payload.key]: payload.payload.value } }
  },
  removeOptinalFieldByKeyVisibility: (state, payload: actionType<{ key: string }>) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData
    if (state[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.optionalFieldVisibility?.[payload.payload.key])
      delete state[sectionIdOrScriptId][versionIdOrStepId][tabName]?.optionalFieldVisibility[payload.payload.key]
  },
  removeAllOptinalFieldVisibility: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    state[sectionIdOrScriptId][versionIdOrStepId][tabName].optionalFieldVisibility = {}
  },
  setDynamicCheckbox: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    state[sectionIdOrScriptId][versionIdOrStepId][tabName].isDynamicAuth = payload.payload.isDynamicAuth || false
  },

  setDynamicInputRequired: (state, payload) => {
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = payload.urlData

    state[sectionIdOrScriptId][versionIdOrStepId][tabName].pluginData = {
      ...state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData,
      dynamicInputRequired: {
        ...state?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData?.dynamicInputRequired,
        ...payload?.payload
      }
    }
  },
  removeDataFromStepsAndBlocks: (state, action) => {
    // this will delete particular steps and blocks using parentkey and key
    const { sectionIdOrScriptId, versionIdOrStepId, tabName } = action.urlData
    const { parentKey = '', key = '' } = action?.payload
    const target = state[sectionIdOrScriptId][action.payload?.stepId || versionIdOrStepId][action.payload?.tabName || tabName].pluginData
    if (parentKey && key) {
      const updatedJson = removeDataFromStepsAndBlocksUtility(target?.inputJson.steps, target?.inputJson.blocks, parentKey, key)
      state[sectionIdOrScriptId][action.payload.stepId || versionIdOrStepId][action.payload.tabName || tabName].pluginData.inputJson = {
        ...target.inputJson,
        ...updatedJson
      }
    } else {
      state[sectionIdOrScriptId][action.payload.stepId || versionIdOrStepId][action.payload.tabName || tabName].pluginData.inputJson = {
        steps: {},
        blocks: {}
      }
    }
  }
}

/**
 * Defines extra reducers for the step reducer.
 * @param {ActionReducerMapBuilder<NoInfer<$StepsReduxTypeV3>>} builder - The builder for defining extra reducers.
 */
export function extraReducers(builder: ActionReducerMapBuilder<NoInfer<$StepsReduxTypeV3>>): void {
  builder
    // save User
    .addCase(setFunctionJsonThunkV3.pending, (state, action) => {
      const { sectionIdOrScriptId, versionIdOrStepId } = action.urlData
      state[sectionIdOrScriptId] = {
        ...state?.[sectionIdOrScriptId],
        [versionIdOrStepId]: { ...state?.[sectionIdOrScriptId]?.[versionIdOrStepId], isLoading: true }
      }
    })
    .addCase(setFunctionJsonThunkV3.fulfilled, (state, action) => {
      const { tabName } = action.urlData
      const payloaddata = action.payload?.data || {}
      const dataFromBackend = { ...payloaddata }
      let draft = {}
      let published = {}

      const { type, title, code, id, published_code, metadata } = payloaddata
      if (type?.includes('function')) {
        draft = {
          type,
          title,
          code,
          id,
          editToggle: true,
          dynamicVariables: metadata?.drafted?.dynamicVariables,
          description: action?.payload?.draftdecription
        }
        published = {
          type,
          title,
          id,
          code: published_code,
          editToggle: true,
          dynamicVariables: metadata?.published?.dynamicVariables,
          description: action?.payload?.publisheddecription
        }
      }
      if (type === 'api') {
        const draftInstanceToAppend = getApiInstanceDataFromCode(code)
        draft = {
          type,
          title,
          code,
          id,
          editToggle: true,
          ...draftInstanceToAppend,
          dynamicVariables: metadata?.drafted?.dynamicVariables
        }
        if (published_code) {
          const publishedInstanceToAppend = getApiInstanceDataFromCode(published_code)
          published = {
            type,
            title,
            id,
            code: published_code,
            editToggle: true,
            ...publishedInstanceToAppend,
            dynamicVariables: metadata?.published?.dynamicVariables
          }
        }
      }
      if (action?.payload?.type === 'plugin') {
        let requiredFieldsList = { root: [] }
        if (state[payloaddata.script_id]?.[payloaddata.id]?.draft?.requiredFieldsList)
          requiredFieldsList = current(state[payloaddata.script_id]?.[payloaddata.id]?.draft?.requiredFieldsList)
        let flags = {}
        if (state[payloaddata.script_id]?.[payloaddata.id]?.draft?.flags)
          flags = current(state[payloaddata.script_id]?.[payloaddata.id]?.draft?.flags)
        const { type, title, code, id, published_code } = payloaddata
        draft = {
          type,
          title,
          code,
          id,
          editToggle: true,
          pluginData: getParsedPluginFieldsInputData(JSON.parse(code) || '{}'),
          requiredFieldsList: requiredFieldsList || { root: [] },
          optionalFieldVisibility: {},
          flags: flags || {},
          isVerify: false,
          isDynamicAuth: false,
          buttonStatus: {
            dryRunButtonState: {
              isLoading: false
            },
            saveButtonState: {
              isLoading: false
            },
            sourceDryRun: {
              isLoading: false
            },
            updateAuthTokenButtonState: {
              dbIdToUpdate: ''
            }
          }
        }
        if (published_code) {
          published = {
            type,
            title,
            id,
            code: published_code,
            editToggle: true,
            pluginData: getParsedPluginFieldsInputData(JSON.parse(published_code) || '{}'),
            requiredFieldsList: { root: [] },
            optionalFieldVisibility: {},
            isVerify: false,
            flags: {},
            isDynamicAuth: false,
            buttonStatus: {
              dryRunButtonState: {
                isLoading: false
              },
              saveButtonState: {
                isLoading: false
              },
              sourceDryRun: {
                isLoading: false
              },
              updateAuthTokenButtonState: {
                dbIdToUpdate: ''
              }
            }
          }
        }
      }
      if (tabName === Tabnames.PUBLISH) {
        state[payloaddata.script_id] = {
          ...state[payloaddata.script_id],
          [payloaddata.id]: {
            ...state?.[payloaddata.script_id]?.[payloaddata.id],
            dataFromBackend,
            published,
            isLoading: false,
            hasUnsavedCode: false
          }
        }
      } else {
        state[payloaddata.script_id] = {
          ...state[payloaddata.script_id],
          [payloaddata.id]: { dataFromBackend, draft, published, isLoading: false, hasUnsavedCode: false }
        }
      }
    })

    .addCase(setFunctionJsonThunkV3.rejected, (state, action) => {
      const { sectionIdOrScriptId, versionIdOrStepId } = action.urlData
      if (!state[sectionIdOrScriptId]) {
        state[sectionIdOrScriptId] = {}
      }
      if (!state[sectionIdOrScriptId][versionIdOrStepId]) {
        state[sectionIdOrScriptId][versionIdOrStepId] = {
          [Tabnames.PUBLISH]: {},
          [Tabnames.DRAFT]: {}
        }
      }
      state[sectionIdOrScriptId][versionIdOrStepId].isLoading = false
    })

    // create new function

    // similary check all conditions for pemding
    .addCase(createOrUpdateFunctionThunk.pending, (state, action) => {
      const { sectionIdOrScriptId, versionIdOrStepId } = action.urlData
      if (!state[sectionIdOrScriptId]) {
        state[sectionIdOrScriptId] = {}
      }
      if (!state[sectionIdOrScriptId][versionIdOrStepId]) {
        state[sectionIdOrScriptId][versionIdOrStepId] = {
          [Tabnames.PUBLISH]: {},
          [Tabnames.DRAFT]: {}
        }
      }
      if (versionIdOrStepId) {
        state[sectionIdOrScriptId][versionIdOrStepId].isLoading = true
      }
    })

    .addCase(createOrUpdateFunctionThunk.fulfilled, (state, action) => {
      if (action?.payload?.type === BlockTypes.COMMENT || !action?.payload) return
      const { scriptId } = action.urlData
      const dataFromBackend = action.payload?.function ? { ...action.payload?.function } : undefined
      let draft = {}
      let published = {}
      if (action?.payload?.function?.type?.includes('function')) {
        const { description } = action?.payload
        const { type, title, code, id, published_code } = action.payload?.function
        draft = { type, title, code, id, editToggle: true, description }
        published = { type, title, id, code: published_code, editToggle: true }
      }

      if (action?.payload?.function?.type === 'api') {
        const { type, title, code, id, published_code } = action.payload?.function
        const draftInstanceToAppend = getApiInstanceDataFromCode(code)
        draft = { type, title, id, editToggle: true, ...draftInstanceToAppend }
        if (published_code) {
          const publishedInstanceToAppend = getApiInstanceDataFromCode(published_code)
          published = { type, title, id, editToggle: true, ...publishedInstanceToAppend }
        }
      }
      if (action?.payload?.function?.type === 'plugin') {
        const { type, title, code, id, published_code } = action.payload?.function
        draft = {
          type,
          title,
          id,
          editToggle: true,
          pluginData: getParsedPluginFieldsInputData(JSON.parse(code) || '{}'),
          requiredFieldsList: { root: [] },
          optionalFieldVisibility: {},
          buttonStatus: {
            dryRunButtonState: {
              isLoading: false
            },
            saveButtonState: {
              isLoading: false
            },
            sourceDryRun: {
              isLoading: false
            },
            updateAuthTokenButtonState: {
              dbIdToUpdate: ''
            }
          }
        }
        if (published_code) {
          published = {
            type,
            title,
            id,
            editToggle: true,
            pluginData: getParsedPluginFieldsInputData(JSON.parse(published_code) || '{}'),
            requiredFieldsList: { root: [] },
            optionalFieldVisibility: {},
            buttonStatus: {
              dryRunButtonState: {
                isLoading: false
              },
              saveButtonState: {
                isLoading: false
              },
              sourceDryRun: {
                isLoading: false
              },
              updateAuthTokenButtonState: {
                dbIdToUpdate: ''
              }
            }
          }
        }
      }
      state[action?.payload?.function?.script_id || scriptId][action?.payload?.function?.id || action?.payload?.stepId] = {
        dataFromBackend,
        draft: {
          ...draft,
          ...state?.[action?.payload?.function?.script_id || scriptId]?.[action?.payload?.function?.id || action?.payload?.stepId]?.draft
        },
        published: {
          ...published,
          ...state?.[action?.payload?.function?.script_id || scriptId]?.[action?.payload?.function?.id || action?.payload?.stepId]
            ?.published
        },
        isLoading: false,
        hasUnsavedCode: false
      }
    })

    .addCase(createOrUpdateFunctionThunk.rejected, (state, action) => {
      const { sectionIdOrScriptId, versionIdOrStepId } = action.urlData
      state[sectionIdOrScriptId][versionIdOrStepId].isLoading = false
    })
}

export const getApiInstanceDataFromCode = (code: string) => {
  const instanceToAppend = typeof code === 'object' ? code : { ...JSON.parse(code || '{}') }
  let otherInstanceAppend = { ...setBodyTypeAndValue(instanceToAppend.postData) }
  otherInstanceAppend = {
    ...otherInstanceAppend,
    queryParams: convertArrToString(instanceToAppend?.queryString) || urlWithParams(instanceToAppend?.url).str,
    headers: convertArrToString(instanceToAppend?.headers),
    url: makeUrl(instanceToAppend?.url, instanceToAppend?.queryString),
    httpRequestType: instanceToAppend?.method?.toLowerCase() || 'get',
    urlHtml: instanceToAppend?.urlHtml || instanceToAppend?.url || '',
    queryParamsHtml: instanceToAppend?.queryParamsHtml || '',
    headerHtml: instanceToAppend?.headerHtml || ''
  }
  return { ...instanceToAppend, ...otherInstanceAppend }
}

const changeObjToArr = (obj: any) => {
  return obj ? Object.keys(obj).map((key) => ({ name: key, value: obj[key] })) : []
}

const handleJsonData = (body: any) => {
  return {
    requestBodyType: '2',
    bodyJsonScript: JSON.stringify(body) || ''
  }
}
const handleRawData = (body: any) => {
  let bodyRawScriptType = ''
  if (body?.header?.['content-type'] === 'text/plain') {
    bodyRawScriptType = 'Text (text/plain)'
  } else if (body?.header?.['content-type'] === 'application/Javascript') {
    bodyRawScriptType = 'JavaScript (application/Javascript})'
  } else if (body?.header?.['content-type'] === 'application/xml') {
    bodyRawScriptType = 'XML (application/xml)'
  } else if (body?.header?.['content-type'] === 'text/xml') {
    bodyRawScriptType = 'XML (text/xml)'
  } else if (body?.header?.['content-type'] === 'text/html') {
    bodyRawScriptType = 'HTML (text/html)'
  }
  const bodyData = {
    requestBodyType: '3',
    bodyRawScript: JSON.stringify(body.body) || '',
    bodyRawScriptType: bodyRawScriptType
  }
  return bodyData
}

export const getApiInstanceDataFromLogCode = (reponseCode: any, scriptId: string) => {
  let dispatchObject = {}
  let headers = cloneDeep(reponseCode?.headers)
  const headersToRemove = ['postman-token', 'content-length', 'accept-encoding', 'user-agent', 'connection', 'authorization', 'host']
  const filteredHeaders = headers ?? {}
  headersToRemove.forEach((header) => delete filteredHeaders[header])
  headers = filteredHeaders
  const url = `${process.env.REACT_APP_VM_URL}/func/${scriptId}?`
  if (reponseCode?.headers?.['content-type'] && reponseCode?.requestType === 'POST') {
    switch (reponseCode?.headers?.['content-type']) {
      case 'application/json':
        dispatchObject = handleJsonData(reponseCode?.body)
        break
      case 'text/html':
        dispatchObject = handleRawData(reponseCode)
        break
      case 'text/plain':
        dispatchObject = handleRawData(reponseCode)
        break
      case 'application/Javascript':
        dispatchObject = handleRawData(reponseCode)
        break
      case 'application/xml':
        dispatchObject = handleRawData(reponseCode)
        break
      case 'text/xml':
        dispatchObject = handleRawData(reponseCode)
        break
      default:
        console.log('Content-Type not recognized.')
    }
  } else {
    console.log('Content-Type header does not exist.')
  }
  return {
    queryParams: convertArrToString(reponseCode?.queryString) || urlWithParams(url).str,
    headers: convertArrToString(changeObjToArr(headers)),
    url: makeUrl(url, changeObjToArr(reponseCode?.query)),
    httpRequestType: reponseCode?.requestType?.toLowerCase() || 'get',
    ...dispatchObject
  }
}

function getUsedVariables(jsonObject) {
  delete jsonObject['usedVariables']
  delete jsonObject['result']
  delete jsonObject['customPayload']

  if (jsonObject?.type === 'function') return fetchUsedVariables(JSON.stringify(jsonObject))
  if (jsonObject?.type === 'api') {
    if (jsonObject.httpRequestType === 'get') {
      delete jsonObject['bodyFormData']
      delete jsonObject['bodyFormUrlencoded']
      delete jsonObject['bodyJsonScript']
      delete jsonObject['bodyRawScript']
    }
    return fetchUsedVariables(JSON.stringify(jsonObject))
  }

  return jsonObject?.usedVariables || undefined
}

export const getPluginDataFromCode = ({
  authCode,
  inputFields,
  source,
  authFields,
  connectionlabelvalue,
  connectionlabelkey,
  serviceId,
  authType,
  serviceName,
  actionVersionId,
  actionName,
  selectedCreate,
  selectedValues,
  authIdLookup,
  queryParamsAndHeaders,
  iconUrl,
  dynamicInputRequired
}: any) => {
  return {
    isLoading: false,
    isAuthLoading: false,
    isVerify: true,
    pluginInfoAirtable: {
      rowid: serviceId,
      authtype: authType,
      actionVersionId: actionVersionId,
      actionName: actionName,
      authInfo: {
        rowid: authIdLookup,
        testcode: authCode,
        type: authType,
        authfields: authFields,
        connectionlabelvalue: connectionlabelvalue,
        connectionlabelkey: connectionlabelkey
      },
      actionDetails: {
        code: queryParamsAndHeaders
      },
      name: serviceName
    },
    pluginFieldsInputData: {
      actionName,
      selectedCreate,
      selectedValues,
      serviceId,
      actionVersionId,
      dynamicInputRequired: dynamicInputRequired || {}
    },
    inputFieldData: { actionVersionId },
    pluginInputJson: { operation: { inputFields, perform: { source } } },
    pluginResponse: { iconUrl }
  }
}
