import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getScopesOfParticularAuth } from '../../api/index'
import { ALERTTYPES } from '../../enums'
import { getOnePluginData, useActionsOrTriggerForAuth, useAuth } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { sendAlert } from '../../utils/alertUtility.ts'
import AuthenticateAuth2Plugin from './authenticateAuth2Plugin.tsx'
import AuthenticatePlugin from './authenticatePlugin.tsx'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'

/*
 * Middleware component for basic and auth2 authentication page.
 * It will check if the user is on DH or Embed mode and redirect to the respective page.
 */
function AuthPage({ serviceId, authId, isUserOnDH, actionid, authidtoupdatetoken }) {
  const location = useLocation()
  const backButton = location?.state?.backButton
  const authData = useAuth(serviceId || '', authId || '', true, Boolean(isUserOnDH)).data
  const plugData = getOnePluginData(serviceId || '', true, Boolean(isUserOnDH))?.data
  const actionList = useActionsOrTriggerForAuth(serviceId || '', 'actionversion')?.data

  const [scopesToUpdateToken, setScopesToUpdateToken] = useState()
  const scopesSeperator = authData?.scopeseperatedby === 'space' ? ' ' : ','
  const [scopesLoaded, setScopesLoaded] = useState(false)

  useEffect(() => {
    if (authData && actionList) {
      getScopesOfThisToken()
    }
  }, [authData, actionList])

  const getScopesOfThisToken = async () => {
    const dataArray = []
    const actionIds = actionid?.split(',')
    const authDataScopes = authData && JSON.parse(authData?.queryparams)

    if (actionIds && actionList) {
      actionIds.forEach((id) => {
        const action = actionList[id]
        if (action && action.scopes) {
          dataArray.push(...action.scopes?.split(scopesSeperator))
        }
      })
    }

    if (authidtoupdatetoken) {
      try {
        const updateAuthTokens = await getScopesOfParticularAuth(authidtoupdatetoken)
        if (updateAuthTokens && updateAuthTokens.scope) {
          dataArray.push(...updateAuthTokens.scope)
        }
      } catch (error) {
        sendAlert(error, ALERTTYPES.FRONTEND, 'getScopesOfParticularAuth api alert')
      }
    }

    if (authDataScopes && authDataScopes.scope) {
      dataArray.push(...authDataScopes.scope?.split(scopesSeperator))
    }

    const uniqueScopes = Array.from(new Set(dataArray))
    const scopes = uniqueScopes.join(scopesSeperator)

    setScopesToUpdateToken(scopes)
    setScopesLoaded(true)
  }

  return (
    <div className='flex-col-center-center'>
      {authData?.type &&
        scopesLoaded &&
        (authData?.type === 'Basic' ? (
          <AuthenticatePlugin authInfo={authData} plugData={plugData} backButton={backButton} />
        ) : (
          <AuthenticateAuth2Plugin // This will called for Auth1 and Auth2 type.
            authInfo={authData}
            plugData={plugData}
            scopes={scopesToUpdateToken}
            backButton={backButton}
            actionId={location?.state?.actionId}
          />
        ))}
    </div>
  )
}

export default React.memo(addUrlDataHoc(React.memo(AuthPage), ['serviceId', 'authId', 'isUserOnDH', 'actionid', 'authidtoupdatetoken']))
