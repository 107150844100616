import React, { useEffect, useState } from 'react'
import { Typography, TextField, Button, Box, Autocomplete, DialogContent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import './formbilling.scss'
import timezoneData from '../../components/setting/timezone'
import { saveOrgs } from '../../store/orgs/orgsThunk'
import { validateOrgName } from '../../utils/utilities'
import { errorToast } from '../../components/customToast'
import { ORG_DEFAULT_TIMEZONE, ParamsEnums, THUNK_CALL_STATUS } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { closeModalRedux, openModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import { selectActiveOrgs } from '../../store/orgs/orgsSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import CustomModal from '../../components/customModal/customModal'
import { ThunkResponseType } from '../../types/types.ts'
import config from '../../config'

interface FormValueObject {
  workspaceName: string
  timezone: {
    offSet: string
    identifier: string
  } | null
  domain: string
}

function CreateOrgModal() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [error, setError] = useState({
    error: false,
    message: ''
  })
  const modalType = useCustomSelector((state) => state.appInfo?.modalType)
  const orgList = useCustomSelector(
    (state: $ReduxCoreType) => selectActiveOrgs(state)?.sort((a, b) => a?.name?.localeCompare(b?.name)) || []
  )

  const [orgListVariable, setOrgListVariable] = useState(orgList || [])
  const [formValueObject, setFormValueObject] = useState<FormValueObject>({
    workspaceName: '',
    timezone: {
      offSet: ORG_DEFAULT_TIMEZONE.utcOffset,
      identifier: ORG_DEFAULT_TIMEZONE.identifier
    },
    domain: ''
  })

  useEffect(() => {
    setError(false)
  }, [])
  const createOrg = async () => {
    try {
      setOrgListVariable([...orgListVariable, { id: 'new_workspace' }])
      if (formValueObject.workspaceName.includes(' ')) {
        setError({ ...error, error: true, message: 'space not allowed' })
        return
      }
      if (!formValueObject.workspaceName) {
        setError({ ...error, error: true, message: 'Enter valid workspace name' })
        return
      }
      if (!validateOrgName(formValueObject.workspaceName, orgList, setOrgListVariable)) return
      const dataToSend = {
        name: formValueObject.workspaceName,
        timezone: formValueObject.timezone?.offSet,
        meta: {
          domainUrl: formValueObject?.domain || '',
          timezoneIdentifier: formValueObject?.timezone?.identifier
        }
      }
      dispatch(closeModalRedux({}))
      dispatch(saveOrgs({ ...dataToSend })).then((data: ThunkResponseType<{ id: string }>) => {
        if (data?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
          // window.location.href = `/activate/${data.payload.id}`
          navigate(`${config.projectsBaseUrl}/${data.payload.id}`)
        } else if (data?.meta?.requestStatus === THUNK_CALL_STATUS.REJECTED) {
          errorToast('Unable to create workspace. It may be a duplicate workspace.')
          setFormValueObject({ ...formValueObject, workspaceName: '' })
        }
      })
    } catch (error) {
      errorToast(error)
    }
  }

  const handleClose = () => {
    dispatch(openModalRedux({ modalType: '' }))
    setFormValueObject({
      workspaceName: '',
      timezone: {
        offSet: ORG_DEFAULT_TIMEZONE.utcOffset,
        identifier: ORG_DEFAULT_TIMEZONE.identifier
      },
      domain: ''
    })
    setError({
      error: false,
      message: ''
    })
  }

  return (
    <CustomModal fullScreen={false} openModal={modalType === 'createOrg'} onClose={handleClose}>
      <DialogContent
        style={{ display: 'flex', flexDirection: 'column', gap: '24px', minWidth: '500px', minHeight: '350px', padding: '36px' }}
      >
        <Box className=' w-100 flex-col gap-4 '>
          <Typography style={{ fontWeight: 'bold' }}>Enter your workspace details</Typography>
          <Box className='flex-col gap-4'>
            <Box>
              <Typography>Workspace name </Typography>
              <TextField
                fullWidth
                error={error}
                helperText={error && error.message}
                size='small'
                inputProps={{ maxLength: 25 }}
                className='mt-2 input-bg-white'
                value={formValueObject.workspaceName}
                onChange={(e) => setFormValueObject({ ...formValueObject, workspaceName: e.target.value })}
                required
                id='outlined-error-helper-text'
                // label="Workspace Name"
                placeholder='Workspace name'
              />
            </Box>
            <Box>
              <Typography>Timezone</Typography>
              <Autocomplete
                id='timezone-select'
                options={timezoneData}
                getOptionLabel={(option) => `${option.identifier} (${option.offSet || ''})`}
                value={formValueObject.timezone}
                isOptionEqualToValue={(option, value) => option.identifier === value.identifier}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFormValueObject({ ...formValueObject, timezone: newValue })
                  }
                }}
                renderInput={(params) => <TextField {...params} variant='outlined' />}
                defaultValue={{ identifier: ORG_DEFAULT_TIMEZONE.identifier, offSet: ORG_DEFAULT_TIMEZONE.utcOffset }}
              />
            </Box>
            <Box>
              <Typography>
                Domain <span>(Optional)</span>
              </Typography>
              <TextField
                fullWidth
                size='small'
                className='mt-2 bg-white'
                value={formValueObject.domain}
                onChange={(e) => setFormValueObject({ ...formValueObject, domain: e.target.value })}
                placeholder='Your official website address'
              />
            </Box>
            <div className='create-org-buttons'>
              <Button variant='outlined' onClick={handleClose}>
                Close
              </Button>
              <Button variant='contained' className='flex-end' onClick={createOrg}>
                Create
              </Button>
            </div>
          </Box>
        </Box>
      </DialogContent>
    </CustomModal>
  )
}

export default React.memo(addUrlDataHoc(React.memo(CreateOrgModal), [ParamsEnums.orgId]))
